@import "../../utils/styles/index.scss";

.MapMenu {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  position: absolute;
  right: 0;
  top: 0;
  padding: 24px;
  font-family: "Montserrat", "San-serif";

  .datasets_label {
    color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    margin-bottom: 24px;

    input {
      opacity: 0;

      &:checked + .datasets_styledCheckbox {
        &::before {
          content: "\2714";
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .datasets_styledCheckbox {
      height: 18px;
      width: 18px;
      border: 2px red solid;
      border-radius: 2px;
      margin-right: 6px;
      position: relative;
    }
  }
}
