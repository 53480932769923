//global color variables

$prussianblue: #0b3c5d;
$skyblue: #328cc1;
$goldleaf: #d9b310;
$ivoryblack: #1d2731;

//media query variables

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
